// SETTINGS

$bar-height: 60px; 	// height for quickbar
$time: 		0.6s; 	// transition for some stuff

body:not(.cms-template-edit) {

	#navigation {
		transition:transform $time;
		background:$dark;
		width:100%;
		overflow-x:hidden;
		position:fixed;
		left:0;
		 z-index:999999;
		bottom:0;
		top:rem($bar-height);
	
		@include breakpoint(small) {
			width:rem(480px);
		}
	
		@include breakpoint(giant) {
			top:0;
			left:100px;
			width:rem(620px);
			background-color: rgba($dark, .7);
			
		}
	
		 nav {
			 overflow: hidden;
			overflow-y: auto;
			// height:calc(100vh - #{rem($bar-height)});
			display:flex;
			flex-wrap:wrap;
			padding:0 0 0 4%;
	
			@include breakpoint(tiny) {
				padding:rem(20px) 0 0 10%;
			}
	
			@include breakpoint(giant) {
				padding:rem(102px) 0 0 rem(90px);
				height:100vh;
			}
	
			.box {
	
				
			}
	
			ul.data {
				font-size:rem(17px);
				line-height:rem(25px);
				margin-bottom:rem(60px);
				border-top:1px solid rgba($light,.5);
				padding:rem(40px) 0 0 rem(17px);
				width:96%;
	
				@include breakpoint(giant) {
					display:none;
				}
	
				li {
					margin-bottom:rem(10px);
					position:relative;
					padding-left:rem(26px);
	
					// a {
					// 	text-decoration:none;
					// 	color: $light;
					// 	&:hover {
					// 		color: darken($light, 30%);
					// 	}
					// }
	
					i {
						position:absolute;
						left:0;
						top:rem(5px);
						font-size: rem(20px);
						
					}
				}
			}
	
			ul:not(.data) {
				width:100%;
				display:flex;
				flex-direction:column;
	
				&.navi-main {
					padding-bottom:rem(50px);
	
					@include breakpoint(giant) {
						padding-bottom:rem(100px);
					}
				}
	
				&.navi-main2 {				
	
					// li.index {
					// 	display: flex;
					// 	order:1;
					// }
	
					// li.shop, li.news, li.newsletter, li.kontakt, li.anfahrt, li.datenschutzerklaerung, li.impressum {
					// 	order:3;
					// }
				}
	
				&.sub {
					transition:transform $time;
					position:relative;
					transform:translateX(0);
					height:100%;
				}
	
				&.current {
					transform:translateX(0%);
	
					&.open {
						overflow:hidden;
						overflow-y:auto;
						-webkit-overflow-scrolling:touch;
					}
				}
	
				li {
					display: flex;
					flex-wrap:wrap;
					width: 100%;				
	
					a, span {
						align-items: center;
						border-bottom: rem(1px) solid rgba($dark,0.1);
						color:$light;
						display: flex;
						justify-content: space-between;
						font-size:rem(17px);
						line-height:rem(32px);
						padding:0 rem($base-gap);
						text-decoration: none;
						width: 100%;
						transition:background $time, color $time;
						cursor:pointer;
	
						@include breakpoint(giant) {
							font-size:rem(19px);
							line-height:rem(34px);
						}
	
						&.sub-toggler {
							display:none;
						}
						&:hover,&.active {
							color: darken($light, 30%);
						}
					}
	
					&.nav-back {
						display:none;
	
						span {
							justify-content: flex-start;
	
							&:before {
								font-family: $icon-font;
								content: map-get($icon-map, angle-right);
								transform:rotate(180deg) translateY(rem(1px));
								margin-right: rem(7px);
							}
						}
					}
	
					.sub, &.index {
	
						&:before {
							padding:rem(25px) rem($base-gap) rem(10px);
							line-height: 100%;
							display: block;
							content:attr(data-parent-name);
							color:$light;
							text-transform:uppercase;
							font-family:$display-font;
							font-size:rem(20px);
							line-height:rem(34px);
							font-weight:400;
							@include breakpoint(large) {
								font-size:rem(22px);
							}
						}
					}
	
					&.qualitaetsbeauftragter {
						margin-top: 25px;
					}
	
					&.active > a, &.active > span, a.active, span.active {
						font-weight: 700;
					}
	
					&:hover > a, &:hover > span {
	
						@include breakpoint(giant) {
							color:rgba($light,.5);
						}
					}
				}
			}
		 }
	}
}
	html.hidden-scroll {
		overflow:hidden;
	}
	
	#quickbar {
		height:rem($bar-height);
		background:$dark;
		display:flex;
		justify-content:space-between;
		align-items:center;
		z-index:1000;
		position:fixed;
		top:0;
		left:0;
		width:100%;
		padding:0 0 0 rem(30px);
		transform:translateY(0%);
		transition:transform $time;	
	
		@include breakpoint(giant) {
			bottom:0;
			width:rem(100px);
			height:100%;
			flex-direction:column-reverse;
			padding:0;
		}
	
		.boxContainer {
			display:flex;
			color:$light;
	
			@include breakpoint(giant) {
				height:calc(100vh - 360px);
				justify-content:space-around;
				align-items:center;
				flex-direction:column;
				min-height:rem(320px);
			}
	
			@include breakpoint(full) {
				min-height:21.333rem;
			}
			.mobile-only {
				@include breakpoint(giant) {
				display:none;
				}
			}
			.box {
				text-align:center;
				color:$light;
				display:flex;
				align-items:center;
	
				@include breakpoint(giant) {
					display:block;
				}
	
				&.contactBox {
					display:none;
					padding-right:rem(30px);
	
					@include breakpoint(giant) {
						padding-right:0;
						display:block;
						padding-top: 28px;
					}
				}
	
				// &.socialBox {
				// 	padding-top:rem(3px);
	
				// 	@include breakpoint(giant) {
				// 		padding-top:0;
				// 		display: none;
				// 	}
	
				// 	li {
				// 		padding-right:rem(12px);
	
				// 		@include breakpoint(giant) {
				// 			padding:0 0 rem(8px);
				// 		}
	
				// 		&:last-child {
				// 			padding:0;
				// 		}
	
				// 		a {
				// 			transition:.5s;
				// 			color: $primary;
	
				// 			&:hover {
				// 				opacity:.6;
				// 			}
				// 		}
				// 		&.phone {
				// 			display: flex;
				// 			align-items: center;
				// 			i {
				// 				margin-right: rem(12px);;
				// 			}
				// 		}
				// 	}
				// }
	
				.title {
					display:none;
					color:$secondary;
					text-transform:uppercase;
					font-family:$display-font;
					font-size:rem(18px);
					font-weight:900;
					transform:rotate(-90deg);
					margin-bottom:rem(26px);
	
					@include breakpoint(giant) {
					}
	
					@include breakpoint(full) {
						margin-bottom:rem(40px);
						display:block;
					}
				}
	
				.line {
					display:none;
					width:1px;
					height:rem(36px);
					background:rgba($light,0.3);
	
					@include breakpoint(full) {
						display:inline-block;
						margin-top: 0.9rem;
						margin-bottom: 0.4rem;
					}
				}
	
				ul {
					display:flex;
	
					@include breakpoint(giant) {
						flex-direction:column;
					}
	
					li {
						padding-right:rem(5px);
						margin-bottom: 0.5rem;
						position:relative;
						transition:.5s;
						color: $primary;
						&:last-child {
							margin-bottom: 0;
						}
						@include breakpoint(giant) {
							padding-right:0;
						}
	
						img {
							width: auto;
						}
	
						.textContainer {
							cursor:text;
							display:inline-block;
							position:absolute;
							left:0;
							top:200%;
							background:lighten($dark,2%);
							overflow:hidden;
							opacity:0;
							transition:.5s;
							padding:rem(14px) rem(18px);
							white-space: nowrap;
							color:$light;
							font-size:rem(14px);
							line-height:rem(24px);
							visibility:hidden;
							text-align:left;
							z-index: 999999;
	
							@include breakpoint(giant) {
								font-size:rem(19px);
								line-height:rem(31px);
								left:200%;
								top:50%;
								transform: translatey(-50%);
								padding:rem(20px) rem(30px);
							}
							&.openings {
								@include breakpoint(giant){
									top: -12%;
								}
							}
	
							// a {
							// 	color:$primary;
							// 	text-decoration:none;
							// 	transition:.5s;
	
							// 	&:hover {
							// 		color:rgba($light,.5);
							// 	}
							// }
	
							strong {
								text-transform:uppercase;
							}

							a {
								color: $light;
								text-decoration: underline;
								&:hover {
									opacity: 0.7;
								}
							}
						}
	
						&:hover {
	
							i {
								opacity:.6;
							}
							
							.textContainer {
								overflow:auto;
								opacity:1;
								top:100%;
								left:0;
								visibility:visible;
	
								@include breakpoint(giant) {
									left:100%;
									top:50%
								}
								&.openings {
									@include breakpoint(giant){
										top: -12%;
									}
								}
							}
						}
					}
				}
			}
		}
	
		label[for="navi-toggled"] {
			display: none;
				body:not(.cms-template-edit) & { 
				display:block;
				cursor:pointer;
				width:rem(80px);
				height:rem($bar-height);
				position:relative;
				overflow:hidden;
				background:$green;
				color:$light;
				transition:.5s;
	
				@include breakpoint(giant) {
					width:rem(100px);
					height: 570px;
				}
				
				&:after{
					content:"";
					position:fixed;
					left:0;
					width:200vw;
					height:200vh;
					display:block;
					background-color:rgba($dark,0.9);
					z-index:-1;
					transition: opacity $time, visibility $time;
					top:rem($bar-height);
	
					@include breakpoint(giant) {
						top:0;
						left:100px;
					}
				}
	
				&:hover {
					@include breakpoint(giant) {
						background-color:darken($green,5%);
					
					}
					
				}
	
				span {
					transition:0.4s linear;
					text-indent:-9999px;
					width:0;
					top:50%;
					transform:translate(-50%, -50%);
					font-weight: 900;
	
					@include breakpoint(giant) {
						transform:translate(-50%, -50%) rotate(-90deg);
						top:37%;
					}
	
					&:before, &:after {
						top:50%;
						transition:top $time ease-in-out, transform $time 0.2s linear;
					}
	
					&:before {
						transform:translate(-50%, -50%) rotate(45deg);
						width:rem(18px);
					}
	
					&:after {
						transform:translate(-50%, -50%) rotate(-45deg);
						width:rem(18px);
					}
				}
	
				span, span:before, span:after {
					content:"";
					position:absolute;
					display:block;
					background:$light;
					height:1.5px;
					left:50%;
					transform-origin:center;
				}
	
				b {
					display:none;
	
					@include breakpoint(giant) {
						display:block;
						text-transform:uppercase;
						position:absolute;
						left:50%;
						top:57%;
						transform:translate(-50%, -50%) rotate(-90deg);
						font-family:$display-font;
						font-size:rem(18px);
						font-weight:900;
						letter-spacing:.1em;
					}
				}
			}	
		}
	}
	
	#navi-toggled {
		display:none;
	
		&:not(:checked) {
	
			 & ~ * #navigation,
			 & ~ #navigation {
				transform:translateX(-150%);
	
				@include breakpoint(giant) {
					transform:translateY(-100%);
				}
			 }
	
			 & ~ #quickbar {
				
				label[for="navi-toggled"] {
	
					span {
						width:27px;
	
						&:before, &:after {
							transform:translate(-50%, -50%);
							transition:top $time 0.2s ease-in-out, transform $time linear, width $time;
						}
	
						&:before {
							top:calc(50% - 5px);
							width:rem(22px);
						}
	
						&:after {
							top:calc(50% + 5px);
							width:rem(22px);
						}
					}
	
					&:after {
						opacity:0;
						 visibility:hidden
					}
				}
	
			 }
			 
		 }
	}
	
	.navi-add {
		text-align:left;
		@include breakpoint(small) {
			display: flex;
			justify-content: space-between;
		}
	
	
		li {
			
			a {
				text-decoration:none;
				font-size: rem(16px);
				color:$light;
				text-transform: uppercase;
				font-weight: 900;
				padding:0.5rem 1rem;
				margin-bottom: 0.3rem;
				background:lighten($dark,10%);
				display: block;
				@include breakpoint(small) {
				background:none;
				padding:0;
				margin:0;
				}
				&:hover,&.active {
					background:$green;
					@include breakpoint(small) {
						background:none;
						color:$medium;
					}
				}
			}
		}
	}
	
