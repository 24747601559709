* {
	margin: 0;
	padding: 0;

	&,
	&:before,
	&:after {
		box-sizing: inherit;
	}
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $base-font-size and $base-line-height in _config.scss
html {
	background: $light;
	box-sizing: border-box;
	color: $dark;
	font-family: $main-font;
	font-size: 100% * ($base-font-size / 16px);
	hyphens: manual;
	line-height: rem($base-line-height);
	scroll-behavior: smooth;

	/* disable text resize in landscape. e.g. on iphone */
	text-size-adjust: none;
}

body {
	line-height: rem($base-line-height);
	opacity: 1 !important;
}

iframe,
[data-iframe] {
	border: none;
	width: 100%;
}

address {
	font-style:normal;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	@extend .margin-bottom;
	display: block;
	text-rendering: optimizeLegibility;
	hyphens: auto;

	/* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/
}

h1,
.h1 {
	font-family: $display-font;
	font-size: clamp(30px,5vw,38px);
	line-height:clamp(40px,5vw,48px);
	text-transform: uppercase;
	font-weight: 900;
	position: relative;
	padding-bottom: rem(35px);
	margin-bottom: rem(45px);
	span {
		display: block;
		color:$medium;
		font-size: clamp(24px,5vw,28px);
		line-height:clamp(34px,5vw,38px);
		margin-top: 0.3rem;
	}
	&:after {
		position: absolute;
		left:0;
		content:'';
		width: rem(272px);
		height: 2px;
		background:$dark;
		bottom: 0;
	}
}

h2,
.h2 {
	font-size: rem($h2-size);
	line-height: 1.3em;
	color:$medium;
	font-weight: 990;
}

h3,
.h3 {
	font-size: rem($h3-size);
	color:$medium;
	font-weight: 990;
}

h4,
.h4 {
	font-size: rem($h4-size);
	color:$medium;
	font-weight: 990;
}

h5,
.h5 {
	font-size: rem($h5-size);
	color:$medium;
	font-weight: 990;
}

h6,
.h6 {
	font-size: rem($h6-size);
	color:$medium;
	font-weight: 990;
}

/**
 * Links
 */
a {
	color: $green;
	word-wrap: break-word;
	@extend %animated-transform;


	&:hover {
		color:$medium;;
	}
	&:focus {
		color: darken($secondary, 50%);
	}
	&:active {
		color: darken($secondary, 30%);
	}

	img {
		border: none;
	}

	&[href^="tel"] {
		color: inherit;
		text-decoration: underline;
	}
}

a:not(.btn):focus,
[tabindex]:focus {
	outline: $outline;
	outline-offset: rem(5px);
}

hr,
.hr {
	display: flex;
	background: $medium;
	border: none;
	clear: both;
	height: rem(1px);
	margin: 1.5rem 0 3rem;
	flex-basis: 100%;
}

ul,
ol {
	list-style: none;

	&.styled-list {
		li {
			padding:1rem;
			background:$dark;
			color:$light;
			margin-right: rem(13px);
			margin-bottom: rem(13px);
			@include breakpoint(small) {
				display:inline-block;
			}

			&:after {
				content:'';
				background:url(../images/layout/list-white.svg);
				width: 11.72px;
				height:11.37px;
				display: inline-block;
				margin-left: 0.5rem;
			}
			a {
				color:$medium;
				&:hover,&:focus {
					color:$light;
				}
			}
		}

		ul {
			margin-left: 1rem;
			margin-bottom: 0;
		}
	}
}

dl {
	&.styled-list {
		@include pie-clearfix;

		dt,
		dd {
			display: block;
			background: $dark;
			color:$light;
			padding: rem(15px);
			a {
				color:$medium;
				&:hover,&:focus {
					color:$light;
				}
			}
		}

		dt {
			padding-bottom: 0;
			font-weight: bold;
		}

		dd {
			padding-top: 0;

			+ dt {
				margin-top: rem(10px);
			}

			+ dd {
				margin-top: rem(-15px);
			}
		}
	}

	&.float-list {
		@include pie-clearfix;

		dt,
		dd {
			display: block;
			vertical-align: top;
			float: left;
		}

		dt {
			padding-right: 0.5rem;
			width: 40%;
			clear: left;
		}

		dd {
			width: 60%;
		}
	}
}

ol {
	&.styled-list
	{
		counter-reset: item;
	
		li {
			counter-increment: item;
	
			&:before {
				content: counters(item, ".") ". ";
			}
		}
	
		ol {
			margin-bottom: 0;
	
			li {
				&:before {
					content: counters(item, ".") " ";
					margin-right: rem(5px);
				}
			}
		}
	}
}

/*
 * responsive images
 */

img {
	vertical-align: bottom;
	float: none;
	height: auto;
	max-width: 100%;
	width: 100%;

	[data-rel] &,
	.lightbox-image & {
		margin-bottom: 0;
	}

	&[data-src] {
		@extend %lazy-loading;
	}
}
.float-img {
	img {
		width: auto;
	}
}
/**
 * Tables
 */
main {
	table {
		display: table;
		border-collapse: collapse;
		width: 100%;
		max-width: 100%;
		text-align: left;
		font-size: rem($base-font-size);

		@include breakpoint(small, max) {
			overflow-x: auto;
			display: block;
		}

		caption {
			text-align: left;
			padding: rem($base-gap) rem($base-gap) rem(25px);
			display: table-caption;
			font-weight: bold;
			font-size: 1.2rem;
		}

		thead {
			border-collapse: collapse;
			display: table-header-group;
		}

		tbody {
			width: 100%;
			overflow-x: scroll;
			display: table-row-group;

			tr {
				padding: rem($base-gap) 0;

				&:not(:last-child) {
					border-bottom: rem(1px) solid $medium;
				}
			}
		}

		tr {
			display: table-row;
		}

		th,
		td {
			padding: rem($base-gap);
			display: table-cell;
			vertical-align: top;
		}

		@include breakpoint(small, max) {
			td {
				min-width: 50vw;
			}
		}
	}
}
