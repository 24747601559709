// Use this for default Icomoon

@include font-face-V2(
 $font-name: "icomoon",
 $file-name: "icomoon",
 $types: (woff, ttf, svg, eot),
 $style: normal,
);

// Example font-face for Roboto
// @include font-face-V2(
//     $font-name: "Roboto", 
//     $file-name: "roboto-v19-latin", 
//     $weights: ("400", "700", "900", "900|italic"), 
//     $types: all
// );

@include font-face-V2(
    $font-name: "Lato", 
    $file-name: "lato-v23-latin", 
    $weights: ("300", "400", "700", "900"), 
    $types: all
);