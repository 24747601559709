
// HEADER
//////////////////////////////
#page-wrap {
	padding-top: rem(60px);
	@include breakpoint(giant) {
		padding-left:rem(100px);
		padding-top: 0;
	}
}
#header {
	position: relative;
	width: 100%;
	body:not(.index) &  {
		margin-bottom: 3rem;
		@include breakpoint(medium) {
			margin-bottom:6rem;
		}
		@include breakpoint(large) {
			margin-bottom:rem(150px);
		}
	}
	.col {
		padding:0 $base-gap;
	}
	.slideshow {

		.swiper-slide {
			background:url(../images/layout/small-bgHeader-01.jpg);
			background-size: cover;
			background-position: center;
			text-align: center;
			padding:3rem 0;
			color:$light;
			@include breakpoint(small) {
				background:url(../images/layout/bgHeader-01.jpg);
				background-size: cover;
				background-position: center;
			}
			body:not(.index) & {
				padding:3rem 0;
			}
				
			@include breakpoint(medium) {
				padding:6rem 0;
			}
			@include breakpoint(large) {
				padding:6rem 0 rem(268px);
			}
			.branding {
				display: inline-block;
				margin-bottom: 3rem;
				@include breakpoint(medium) {
					margin-bottom:6rem;
				}
				@include breakpoint(full) {
					margin-bottom: rem(160px);
				}
				body:not(.index) & {
					margin-bottom: 0;
				}	
			}
			.claim {
				body:not(.index) & {
					display: none;
				}
			}
			p {
				margin-bottom: 1.5rem;
				&.headline {
					font-size: clamp(24px,5vw,70px);
					line-height: clamp(34px,5vw,80px);
					font-weight: 900;
					text-transform: uppercase;
					span {
						-webkit-text-stroke: 1px #fff;
						color: transparent;
					}
				}
			}
			ul {
				margin-bottom: 1.5rem;
				li {
					padding:1rem;
					background:$dark;
					color:$light;
					margin-right: rem(13px);
					margin-bottom: rem(13px);

					&:after {
						content:'';
						background:url(../images/layout/list-white.svg);
						width: 11.72px;
						height:11.37px;
						display: inline-block;
						margin-left: 0.5rem;
					}
					@include breakpoint(small) {
						display:inline-block;
					}
				}
			}
			.row {
				justify-content: center;
			}
			&.slide-02 {
				background:url(../images/layout/small-bgHeader-02.jpg);
				background-size: cover;
				background-position: center;
				@include breakpoint(small) {
					background:url(../images/layout/bgHeader-02.jpg);
					background-size: cover;
					background-position: center;
				}
			}
		}
	}
}
.teaserContainer {
	.ts {
		display: block;
		background:$dark;
		color:$light;
		padding:6rem 1.5rem;
		text-align: center;
		text-decoration: none;
		position: relative;
		@include breakpoint(medium) {
			padding:rem(140px) 1.5rem;
		}
		// @include breakpoint(large) {
		// min-height:rem(410px);
		// }
		&:after {
			position: absolute;
			content:'';
			right:0;
			bottom: 0;
		}
		.btn {
			margin-bottom: rem(40px);
			margin-left: -1.5rem;
			@include breakpoint(large) {
				font-size:rem(28px);
			}
			@include breakpoint(huge) {
				font-size:rem(24px);
			}
			@include breakpoint(cut) {
				font-size:rem(28px);
			}
		}
		.info {
			display: block;
			strong {
				font-weight: 900;
			}
		}
		&.ts-01 {
			background:lighten($dark,10%);
			&:after {
				width: rem(269px);
				height:rem(307px);
				background:url(../images/layout/ts-01.svg)no-repeat;
			}
		}
		&.ts-02 {
			background:lighten($dark,5%);
			&:after {
				width: rem(257px);
				height:rem(315px);
				background:url(../images/layout/ts-02.svg)no-repeat;
			}
		}
		&.ts-03 {
			&:after {
				width: rem(277px);
				height:rem(282px);
				background:url(../images/layout/ts-03.svg)no-repeat;
			}
		}
		&:hover {
			background:$green;
			.btn {
				font-size: rem(22px);
				&:before {
					width: 0;
				}
				@include breakpoint(large) {
					font-size:rem(30px);
				}
				@include breakpoint(huge) {
					font-size:rem(26px);
				}
				@include breakpoint(cut) {
					font-size:rem(30px);
				}
			}
		}
	}
	.col {
		padding: 0;
	}
}

.contentContainer {
	padding:3rem 0;
	@include breakpoint(medium) {
		padding:6rem 0;
	}
	@include breakpoint(large) {
		padding:rem(150px) 0;
	}
	.bulb-container {
		background:$lightgrey;
		margin-top: 1.5rem;
		padding:3rem 1.5rem 1.5rem 1.5rem;
		position: relative;
		margin-bottom: 1.5rem;
		@include breakpoint(large) {
			padding:rem(60px) rem(30px) rem(60px)-1.5rem rem(30px);
			margin-bottom: 0;
		}
		@include breakpoint(huge) {
			padding:rem(100px) rem(50px) rem(100px)-1.5rem rem(50px);
		}
		@include breakpoint(full) {
			padding:rem(100px) 0 rem(100px)-1.5rem rem(100px);
			margin-top: 3.5rem;
		}
		&:after {
			@include breakpoint(large) {
				position:absolute;
				height:100%;
				content:'';
				width:10rem;
				right:0;
				background:$lightgrey;
				margin-right: -10rem;
				top:0;
				z-index: -1;
			}
		}
	}
	figure {
		@include breakpoint(large) {
			height:100%!important;
		}
		img {
			margin-bottom: 0;
			@include breakpoint(large) {
				height:100%!important;
				object-fit: cover;
			}
		}
	}
}


.familyContainer {
	@include responsive-image('layout/bgFamily.jpg', (small, medium, large), true);
	background-size: cover;
	background-position: center;
	padding:3rem 0;
	text-align: center;
	color:$light;
	@include breakpoint(medium) {
		padding:6rem 0;
	}
	@include breakpoint(large) {
		padding:rem(150px) 0;
	}
	@include breakpoint(giant) {
		padding:rem(195px) 0;
	}
	p {
		&.headline {
			font-size: clamp(24px,5vw,70px);
			line-height: clamp(34px,5vw,80px);
			font-weight: 900;
			text-transform: uppercase;
			span {
				-webkit-text-stroke: 1px #fff;
				color: transparent;
			}
		}
		a {
			color:$light;
			&:hover {
				color:$medium;
			}
		}
	}
	
	.row {
		justify-content: center;
	}
}

.secondTeaser {
	text-align: center;
	p {
		&.headline {
			font-size: clamp(24px,5vw,70px);
			line-height: clamp(34px,5vw,80px);
			font-weight: 900;
			text-transform: uppercase;
			span {
				-webkit-text-stroke: 1px $dark;
				color: transparent;
			}
		}
	}
	.row {
		justify-content: center;
	}
	.ts {
		display: block;
		text-decoration: none;
		.inner {
			min-height: rem(391px);
			text-align: left;
			display: flex;
			padding:1rem;
			align-items: center;
			justify-content: center;
			position: relative;
			&:after {
				@include breakpoint(small) {
					position: absolute;
					content:'';
					width: 2px;
					height:rem(98px);
					left:50%;
					transform: translatex(-50%);
					bottom: 0;
					background:$dark;
					margin-bottom: rem(-62px);
				}
			}
			ul {
				margin-bottom: 0;
				opacity: 0;
				transition: 0.6s;
				z-index: 9;
				li {
					padding: 0;
					background: none;
					display: block;
					margin-right: 0;
				}
			}
			&:before {
				position: absolute;
				left:0;
				top:0;
				height: 100%;
				width: 100%;
				z-index: 0;
				content:'';
				transition: 0.6s;
			}
		}
		&:hover {
			.inner {
				&:before {
					background:rgba($dark,0.6);
				}
				&:after {
					height: 0;
				}
				ul {
					opacity: 1;
				}
			}
			.btn {
				opacity: 0;
			}
		}
		.btn {
			color:$dark;
			position: relative;
			margin-top: rem(93px);
			display: none;
			margin-left: -1.5rem;
			@include breakpoint(small) {
				display:inline-block;
				font-size: rem(16px);
			}
			@include breakpoint(giant) {
				font-size: rem(18px);
			}
			@include breakpoint(huge) {
				font-size: rem(20px);
			}
			&:after {
				background:url(../images/layout/after-button-dark.svg);
			}
			&:before {
				background: $green;;
			}
		}
		&.ts-01 {
			margin-bottom: 1.5rem;
			@include breakpoint(small) {
			margin-bottom:3rem;
			}
			@include breakpoint(large) {
			margin-bottom:0;
			}
			.inner {
				&.is-on-screen {
					background: url(../images/layout/ts-01.jpg);
					background-size: cover;
					background-position: center;
				}
			}
		}
		&.ts-02 {
			margin-bottom: 1.5rem;
			@include breakpoint(small) {
			margin-bottom:3rem;
			}
			@include breakpoint(large) {
			margin-bottom:0;
			}
			.inner {
				&.is-on-screen {
					background: url(../images/layout/ts-02.jpg);
					background-size: cover;
					background-position: center;
				}
			}
		}
		&.ts-03 {
			.inner {
				&.is-on-screen {
					background: url(../images/layout/ts-03.jpg);
					background-size: cover;
					background-position: center;
				}
			}
		}
	}
}


.unternehmenOutter {
	padding:3rem 0;
	@include breakpoint(medium) {
		padding:6rem 0;
	}
	@include breakpoint(large) {
		padding:rem(150px) 0;
	}
	@include breakpoint(huge) {
		padding:rem(150px) 0 rem(150px)+1.5rem;
	}
	@include breakpoint(full) {
		padding:rem(150px) 0 rem(150px)+3rem;
	}
	.unternehmenContainer {
		background: $lightgrey;
		padding:3rem 1.5rem 3rem 1.5rem;
		margin-bottom: 1.5rem;
		.cms-video {
			@include breakpoint(huge) {
				margin-bottom: -7rem;
			}
			@include breakpoint(full) {
				margin-bottom: -15rem;
				height: 495px !important;
			}
		}
		@include breakpoint(full) {
		padding:rem(111px) rem(86px);
		}
	}
	.btn {
		color:$dark;
		margin-bottom: 1.5rem;
		&:after {
			background:url(../images/layout/after-button-dark.svg);
		}
		&:before {
			background: $green;;
		}
	}
	.btn-container {
		position: relative;
		@include breakpoint(tiny) {
			padding-left:rem(67px);
		}
		@include breakpoint(full) {
			margin-left:rem(86px);
			margin-top: 3rem;
		}
		&:before {
			@include breakpoint(tiny) {
				content:'';
				position: absolute;
				width: rem(46px);
				height:rem(35px);
				background:url(../images/layout/rundgang.svg);
				background-size: cover;
				left:0;
				top:46%;
				transform: translatey(-50%);
			}
		}
	}
}

.contact-container {
	form.default {
		label {
			display: none;
			&[for*="privacy-policy"],  &[for*="datenschutz"] {
				display: block;
			}
		}
		input {
			margin: 0 0 1rem 0;
		}
		textarea[name="Nachricht"] {
			min-height: 7.5rem;
		}
	}
	.h1 {
		p {
			margin-bottom: 0;
		}
	}
	.shop-container {
		background:$lightgrey;
		margin-top: 1.5rem;
		padding:3rem 1.5rem 3.5rem 1.5rem;
		position: relative;
		@include breakpoint(large) {
			padding:rem(60px) rem(30px) rem(60px)+0.5rem rem(30px);
		}
		@include breakpoint(huge) {
			padding:rem(100px) rem(50px) rem(100px)+0.5rem rem(50px);
		}
		@include breakpoint(full) {
			padding:rem(100px) rem(100px) rem(100px)+0.5rem 0;
			margin-top: 3.5rem;
		}
		@include breakpoint(cut) {
			padding:rem(100px) rem(220px) rem(100px)+0.5rem 0;
			margin-top: 3.5rem;
		}
		&:after {
			@include breakpoint(large) {
				position:absolute;
				height:100%;
				content:'';
				width:10rem;
				left:0;
				background:$lightgrey;
				margin-left: -10rem;
				top:0;
				z-index: -1;
			}
		}
		&:before {
			@include breakpoint(large) {
				position:absolute;
				content:'';
				right:0;
				bottom:0;
				height:rem(230px);
				width:rem(220px);
				background:url(../images/layout/shop.svg) no-repeat;
				background-position: center;
			}
		}
		.btn {
			color:$dark;
			&:hover {
				color:$green;
			}
			&:before {
				background: $green;
			}
			&:after {
				background:url(../images/layout/after-button-dark.svg);
			}
		}
	}
	figure {
		margin-bottom: 1.5rem;
		@include breakpoint(large) {
			height:100%!important;
			margin-bottom: 0;
		}
		img {
			margin-bottom: 0;
			@include breakpoint(large) {
				height:100%!important;
				object-fit: cover;
			}

			@include breakpoint(full) {
				padding-right:50px
			}
		}
	}
}
// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	
	p,
	ol,
	ul,
	dl,
	table {
		@extend .margin-bottom ;
		strong {
			font-weight: 900;
		}
	}

	ul:not(.unstyled-list),
	dl:not(.unstyled-list),
	ol:not(.unstyled-list) {
		@extend .styled-list;
	}

	img {
		margin-bottom:rem($base-gap*2);
	}
}

#privacy-policy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	.footerContainer {
		padding:3rem 0 0;
		margin-top: 3rem;
		background:$dark;
		color:$light;
		position: relative;
		@include breakpoint(medium) {
			margin-top:6rem;
		}
		@include breakpoint(large) {
			padding:6rem 0;
			margin-top: rem(150px);
		}
		.branding {
			display: inline-block;
			margin-bottom: 1.5rem;
		}
		.hr {
			background:rgba($light,0.3);
			margin:1.5rem 0;
			@include breakpoint(large) {
				margin:3rem 0;
			}
		}
		p {
			.block {
				display: block;
				float: left;
				width: 4.5rem;
			}
			a {
				color:$light;
				&:hover {
					color:$medium;
				}
			}
		}
		iframe {
			margin-top: 3rem;
			height: 300px;
			margin-bottom: -8px;
			@include breakpoint(large) {
				position:absolute;
				left:0;
				top:0;
				height:100%;
				width:35%;
				margin-top:0;
			}
			@include breakpoint(huge) {
				width:45%;
			}
			@include breakpoint(full) {
				width:50%;
			}
		}
	}
}

body.cms-page-edit {
	.no-cms {
		display: none;
	}
}

//Pop Up
.popUpContainer {
 
	body:not(.cmsBackend) & {
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		overflow: hidden;
		transition: opacity .3s, visibility .3s;
		position: fixed;
		visibility: visible;
		opacity: 1;
		z-index: 1000000;
 
		.popUpContent {
            width: 100%;
			background: $light;
			max-height: 90vh;
			max-width: calc(100% - #{rem(110px)}); // for the close label
	    	overflow: hidden;
	        overflow-y: auto;
			padding: 1rem;
	 
			@include breakpoint(giant) {
				max-width: 80%;
			}
	 
			@include breakpoint(full) {
				max-width: 70%;
			}
		}
	}
 
	label {
		display:none;
 
		body:not(.cmsBackend) & {
			background: rgba(black, 0.8);
			display: flex;
			height: 100%;
			justify-content: flex-end;
			padding: rem($base-gap);
			position: absolute;
			right: 0;
			top: 0;
			width: 100%;
			z-index: -1;
	 
			i {
				cursor: pointer;
				display: block;
				height: rem(35px);
				width: rem(35px);
				position: relative;
				transition: background .3s;
	 
				&:hover {
					background: rgba(black, 0.8);
	 
					&:before, &:after {
						background: white;
					};
				}
	 
				&:before, &:after {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					width: rem(30px);
					height: rem(2px);
					background: #ddd;
				}
	 
				&:before {
					transform: translate(-50%,-50%) rotate(45deg);
				}
	 
				&:after {
					transform: translate(-50%,-50%) rotate(-45deg);
				}
			}
		}
	}
}
 
input[id^="popUpToggled"] {
	display: none;
 
	&:not(:checked) {
 
		+ {
 
			.popUpContainer {
				position: relative;
				opacity: 0;
				visibility: hidden;
				z-index: -1000000;
				transform:scale(0);
				pointer-events: none;
				width: 0;
				height: 0;
			}
		}
	}
}

// wishlist
body.online-shop  {
	.contentBox, .cms-module {
		.btn {
			background-color: $dark;
			font-size: .7rem;
			&:after {
				display: none;
			}
			&:not(.btn-cart-indicator):before {
				display: none;
			}
			&:not([data-wishlist-remove-item]):not(.btn-cart-indicator) {
				padding: .5rem .8rem;
			}
		}
		.btn-cart-indicator {
			display: inline-flex;
			padding: .5rem;
		}
		form.wishlist-form {
			padding: 1rem;
		}
		.wishlist-item-text .h3 {
			font-size: 1rem;
		}
		@include breakpoint(tiny, max) {
			.wishlist-item-content {
				flex-direction: column;
				align-items: start;
			}
		}
		[data-wishlist-price] {
			font-weight: bold;
		}
		.accHandler + * {
			min-height: 0;
		}
		[data-cart-add], [data-cart-remove] {
			margin-bottom: 2rem;
		}
		[data-cart-remove] {
			background-color: lighten($dark, 15%);
		}
	}
}